import React from "react";
import Content from "../components/Content";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const Home = () => {
  return (
    <Layout>
      <SEO title="Information" slug="/" />
      <Content />
    </Layout>
  );
};

export default Home;
