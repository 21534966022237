import React from "react";
import { Tab, Transition } from "@headlessui/react";
import { DocumentSecurity, Telephone, Envelope } from "@carbon/pictograms-react";
import { ArrowRight, Launch } from "@carbon/icons-react";
import { StaticImage } from "gatsby-plugin-image";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Content = () => {
  return (
    <div className="pb-12">
      <div className="bg-[#e5e5e5] border-b border-zinc-300">
        <div className=" h-52 flex px-4 max-w-7xl mx-auto items-end py-12 pt-36 lg:pt-60">
          <h1 className="text-ni-carbon text-4xl lg:text-5xl">Nominal Insurer Tasmania</h1>
        </div>
      </div>
      <div className="">
        <Tab.Group as="div" className="">
          <div className="bg-[#e5e5e5] w-full sticky top-12 z-10">
            <Tab.List className="flex h-12 max-w-7xl mx-auto">
              <Tab
                className={({ selected }) =>
                  classNames(
                    "transition text-left py-1 pl-4 pr-4 text-sm font-medium leading-5 text-ni-dd-hover border-t-2 border-ni-divide hover:bg-zinc-300",
                    "focus:outline-none",
                    selected
                      ? "font-semibold border-ni-green text-ni-carbon bg-zinc-300"
                      : "hover:text-ni-carbon hover:border-ni-carbon"
                  )
                }
              >
                Organisation
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    "transition text-left py-1 pl-4 pr-4 text-sm font-medium leading-5 text-ni-dd-hover border-t-2 border-ni-divide hover:bg-zinc-300",
                    "focus:outline-none",
                    selected
                      ? "font-semibold border-ni-green text-ni-carbon bg-zinc-300"
                      : "hover:text-ni-carbon hover:border-ni-carbon"
                  )
                }
              >
                Resources
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    "transition text-left py-1 pl-4 pr-4 text-sm font-medium leading-5 text-ni-dd-hover border-t-2 border-ni-divide hover:bg-zinc-300",
                    "focus:outline-none",
                    selected
                      ? "font-semibold border-ni-green text-ni-carbon bg-zinc-300"
                      : "hover:text-ni-carbon hover:border-ni-carbon"
                  )
                }
              >
                Contact
              </Tab>
            </Tab.List>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <div className="max-w-7xl mx-auto pt-12 lg:pt-24 px-4">
                <h3 className="text-ni-carbon text-2xl lg:text-3xl lg:pr-36">
                  The Nominal Insurer Tasmania is an independent statutory body
                  established to ensure that injured Tasmanian workers are not
                  disadvantaged where:
                </h3>
                <ul className="list-disc py-8 pl-12">
                  <li className="text-ni-carbon text-lg py-2">
                    the employer does not hold a policy of workers compensation
                    insurance;
                  </li>
                  <li className="text-ni-carbon text-lg py-2">
                    the employer cannot be located;
                  </li>
                  <li className="text-ni-carbon text-lg py-2">
                    the employer is insolvent (bankrupt);
                  </li>
                  <li className="text-ni-carbon text-lg py-2">
                    the employer’s insurer is insolvent or is unable to meet its
                    liabilities under the policy.
                  </li>
                </ul>
                <p className="text-ni-carbon text-lg pb-4">
                  If you are a worker who believes that one of the above
                  circumstances may apply, you must still lodge a claim for
                  compensation on your employer. If the employer fails to meet
                  its obligations (for example, to make weekly payments or pay
                  compensation for medical and other expenses), the worker, or a
                  representative of the worker, can refer the matter to the
                  Workers Rehabilitation and Compensation Tribunal for an order
                  requiring the employer to pay compensation.
                </p>
                <p className="text-ni-carbon text-lg pb-4">
                  If the employer fails to comply with the Tribunal’s order, the
                  worker can seek a further order requiring the Nominal Insurer
                  to meet the employer’s liability for the claim.
                </p>
                <p className="text-ni-carbon text-lg pb-4">
                  If the order is made, the Nominal Insurer will then manage the
                  worker’s claim.
                </p>
                <p className="text-ni-carbon text-lg pb-4">
                  The governing legislation for the Nominal Insurer{" "}
                  <a
                    href="http://www.thelaw.tas.gov.au/tocview/index.w3p;cond=;doc_id=4%2B%2B1988%2BAT%40EN%2B20150402110000;histon=;prompt=;rec=;term="
                    target="blank"
                    className="italic underline text-zinc-500 hover:text-ni-carbon"
                  >
                    Workers Rehabilitation and Compensation Act 1988
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.legislation.tas.gov.au/view/html/inforce/current/sr-2021-049"
                    target="blank"
                    className="italic underline text-zinc-500 hover:text-ni-carbon"
                  >
                    Workers Rehabilitation and Compensation Regulations 2021
                  </a>
                </p>
                <p className="text-ni-carbon text-lg pb-4">
                  For more information or to submit a claim:
                </p>
                <div className="py-12">
                  <a
                    href="mailto:secretary@nominalinsurer.com.au"
                    className="px-4 py-3 border border-ni-green text-base font-medium  shadow-sm text-ni-green bg-transparent hover:bg-ni-green hover:text-black transition sm:px-8"
                  >
                    Message the Secretary
                  </a>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="max-w-7xl mx-auto pt-12 lg:pt-24 px-4">
                <h3 className="text-ni-carbon text-2xl lg:text-3xl lg:pr-36">
                  The resources and affiliations below provide further
                  information about the powers and jurisdication of the Nominal
                  Insurer. Please direct further questions to our secretary.
                </h3>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pt-12">
                  <a
                    href="https://workerassist.org.au/"
                    target="blank"
                    className="flex flex-col p-4 hover:bg-[#e5e5e5] transition border-zinc-200 border"
                  >
                    <h3 className="text-xl text-gma-carbon pb-6">
                      Worker Assist
                    </h3>
                    <p className="pr-24 h-60">
                      Worker Assist Tasmania Inc. can help by providing free and
                      independent advice about workers compensation,
                      rehabilitation and return-to-work.
                    </p>
                    <div className="flex justify-between items-end">
                      <StaticImage src="../assets/worker-assist.png" />
                      <Launch className="h-5 w-5" />
                    </div>
                  </a>
                  <a
                    href="https://www.lst.org.au/"
                    target="blank"
                    className="flex flex-col p-4 hover:bg-[#e5e5e5] transition border-zinc-200 border"
                  >
                    <h3 className="text-xl text-gma-carbon pb-6">
                      Law Society of Tasmania
                    </h3>
                    <p className="pr-24 h-60">
                      Find information on the Society’s Pro Bono Referral
                      Service, Tasmania Legal Aid, and for a list of other
                      resources to assist if you are seeking help with a legal
                      matter.
                    </p>
                    <div className="flex justify-between items-end">
                      <StaticImage src="../assets/law-society.png" />
                      <Launch className="h-5 w-5" />
                    </div>
                  </a>
                  <a
                    href="https://www.worksafe.tas.gov.au/"
                    target="blank"
                    className="flex flex-col p-4 hover:bg-[#e5e5e5] transition border-zinc-200 border"
                  >
                    <h3 className="text-xl text-gma-carbon pb-6">
                      Worksafe Tasmania
                    </h3>
                    <p className="pr-24 h-60">
                      Find comprehensive detail and links for information on
                      Workers Compensation in Tasmania
                    </p>
                    <div className="flex justify-between items-end">
                      <StaticImage src="../assets/worksafe.png" />
                      <Launch className="h-5 w-5" />
                    </div>
                  </a>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
            <div className="max-w-7xl mx-auto pt-12 lg:pt-24 px-4">
                <h3 className="text-ni-carbon text-2xl lg:text-3xl lg:pr-36">
                  The acting Secretary for the Nominal Insurer is Greg Mathews.
                  For all inquiries regarding new or active claims, contact us
                  via email for the quickest response time.
                </h3>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pt-12">
                  <div className="flex flex-col p-4 hover:bg-[#e5e5e5] transition border-zinc-200 border">
                    <h3 className="text-xl text-gma-carbon pb-6">
                      Mailing Address
                    </h3>
                    <p className="pr-24 h-60">PO Box 56, Rosny Park TAS 7018</p>
                    <div className="flex justify-between items-end">
                      <DocumentSecurity className="" />
                      <ArrowRight className="h-5 w-5" />
                    </div>
                  </div>
                  <a
                    href="mailto:secretary@nominalinsurer.com.au"
                    className="flex flex-col p-4 hover:bg-[#e5e5e5] transition border-zinc-200 border"
                  >
                    <h3 className="text-xl text-gma-carbon pb-6">
                      Phone Numbers
                    </h3>
                    <p className="pr-24 h-60">
                      The Nominal Insurer are currently not accepting enquiries by phone. Please direct all general enquiries to: secretary@nominalinsurer.com.au
                    </p>
                    <div className="flex justify-between items-end">
                      <Telephone className="" />
                      <Launch className="h-5 w-5" />
                    </div>
                  </a>
                  <a
                    href="mailto:secretary@nominalinsurer.com.au"
                    className="flex flex-col p-4 hover:bg-[#e5e5e5] transition border-zinc-200 border"
                  >
                    <h3 className="text-xl text-gma-carbon pb-6">
                      Email Address
                    </h3>
                    <p className="pr-24 h-60">
                      Direct all general enquiries to:
                      secretary@nominalinsurer.com.au
                    </p>
                    <div className="flex justify-between items-end">
                      <Envelope className="" />
                      <Launch className="h-5 w-5" />
                    </div>
                  </a>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Content;
